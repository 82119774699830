/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { AppBadge, ModalDialog } from '@launchpad';
import UsersModel from '../../models/UsersModel';
import { LoadingOverlay } from '../../../../../../@launchpad/index';
import KycCollapsibleItem from './components/KYCCollapsibleItem';
import PepDetailsModal from '@baseProject/configuration/modules/organisations/modals/PepDetailsModal';
import SisDetailsModal from '@baseProject/configuration/modules/organisations/modals/SisDetailsModal';
import ToastHelper from '@launchpad/util/ToastHelper';
import OrganisationModel from '@baseProject/configuration/modules/organisations/models/OrganisationModel';
import { KYBStatus } from '@launchpad/modules/auth/types/auth.types';

class KycInfo extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      redirect: false,
      isLoading: false,
      KYCDocumentsUrls: [],
      KYCCheckInformation: null,
      PEPCheckInformation: null,
      pepDetails: [],
      sisDetails: [],
      SISCheckInformation: null,
      compareResults: null,
      uboDetailsResponse: null,
      kycTestsTableCollapsed: false
    };
  }

  componentDidMount() {
    this._pullKYCDocuments();
    this._pullKYCCheckInformation();
  }

  onChangeUserStatusKyc(id, status) {
    this.setState({ isLoading: true });
    new UsersModel()
      .changeUserKycKybStatusBasedOnKyb(id, status)
      .then(() => {
        this.props.onRefresh();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  toggleCollapse = name => {
    this.setState(prevState => ({ [name]: !prevState[name] }));
  };

  async _pullKYCDocuments() {
    const { id } = this.props.match.params;

    await new UsersModel().getKycDocument(id).then(result => {
      if (result && result.publicUrls) {
        this.setState({
          KYCDocumentsUrls: result.publicUrls
        });
      }
    });
  }

  async _pullKYCCheckInformation() {
    const { id } = this.props.match.params;

    await new UsersModel().getKYCCheckInformation(id).then(result => {
      if (result && result.length > 0) {
        this.setState({
          KYCCheckInformation: result[0],
          PEPCheckInformation: result[1],
          SISCheckInformation: result[2],
          compareResults: result[3],
          uboDetailsResponse: result[4]
        });
      }
    });
  }

  async repeatCheck(checkType, profileId = undefined, type = 'pep') {
    const { id: ownerId } = this.props.match.params;
    await new OrganisationModel()
      .triggerCheck(
        ownerId,
        ownerId,
        this.props.user.fullName?.toUpperCase(),
        checkType
      )
      .then(result => {
        // Skip this if user fetched details for pep check
        if (!profileId) {
          this._pullKYCCheckInformation();
          ToastHelper.show(
            `You have successfully repeat ${checkType} check`,
            'success'
          );
        } else {
          // eslint-disable-next-line no-lonely-if
          if (type === 'pep') {
            // show modal with result information
            this.setState({
              pepDetails:
                result?.result?.results?.profileDetailsResult
                  ?.pepDeskDetailsResult?.functionDetails || [],
              showPepDetailsModal: true
            });
          } else {
            // type === 'sis'
            this.setState({
              sisDetails:
                result?.result?.results?.profileDetailsResult
                  ?.sisPlusDetailsResult,
              showSisDetailsModal: true
            });
          }
        }
      });
  }

  renderResult(status) {
    // hardcoded values should be removed
    switch (status.toUpperCase()) {
      case 'PASSED':
      case 'VERIFIED':
      case KYBStatus.VERIFIED:
        return <AppBadge text={status} icon="check" />;
      case 'FAIL':
      case 'FAILED':
      case 'DECLINE':
      case KYBStatus.REJECTED:
        return <AppBadge text={status} icon="times" type="danger" />;
      case KYBStatus.VERIFICATION_IN_PROGRESS:
        return <AppBadge text={status} icon="clock-o" type="info" />;
      case 'REFER':
      case 'REFERRED':
      case KYBStatus.REFERRED:
        return (
          <AppBadge
            text={status}
            icon="clock-o"
            type="warning"
            style={{ color: 'white' }}
          />
        );
      default:
        return <AppBadge text={status} icon="clock-o" type="info" />;
    }
  }

  render() {
    const { user } = this.props;

    if (this.state.redirect) {
      return <Redirect to={`/user-details/${user.id}`} />;
    }
    const userStatus =
      user.kybStatus?.toUpperCase() || KYBStatus.PENDING_ACCOUNT_TYPE;

    return (
      <div className="main" key={user}>
        <div className="dark-blur" />
        <div className="customers-info">
          <div className="customer-details">
            <div>
              <h2 className="customer-name">
                <span className="company">
                  {user.firstName} {user.lastName}
                </span>
              </h2>
            </div>
          </div>

          <div className="customers-main">
            <div className="row">
              <div className="col">
                {!user || this.state.isLoading ? (
                  <LoadingOverlay />
                ) : (
                  <div className="padded">
                    <div className="row text-white">
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>KYC</h4>
                        <hr />
                        <dl style={{ marginBottom: 10 }}>
                          <dt>Status:</dt>
                          <dd>{this.renderResult(userStatus)}</dd>
                        </dl>
                        <dl>
                          <dt>Change KYC status:</dt>
                          <dd>
                            <select
                              className="option-wrapper"
                              onChange={event =>
                                this.onChangeUserStatusKyc(
                                  user.id,
                                  event.target.value
                                )
                              }
                              value={userStatus || ''}
                            >
                              <option disabled value="">
                                -- select an option --
                              </option>
                              <option value={KYBStatus.PENDING_USER_DETAILS}>
                                Pending user details
                              </option>
                              <option value={KYBStatus.PENDING_USER_DOCUMENTS}>
                                Pending user documents
                              </option>
                              <option
                                value={KYBStatus.VERIFICATION_IN_PROGRESS}
                              >
                                Verification in progress
                              </option>
                              <option value={KYBStatus.REFERRED}>
                                Referred
                              </option>
                              <option value={KYBStatus.REJECTED}>
                                Rejected
                              </option>
                              <option value={KYBStatus.VERIFIED}>
                                Verified
                              </option>
                            </select>
                          </dd>
                        </dl>
                        <KycCollapsibleItem
                          title="KYC Check Information"
                          repeatCheck={() => this.repeatCheck('KYC')}
                          arrayOfItems={[
                            {
                              label: 'Result',
                              value: this.state.KYCCheckInformation?.response
                                ?.verification?.result
                            },
                            {
                              label: 'Classification result',
                              value: this.state.KYCCheckInformation?.response
                                ?.classification?.classificationResult
                            },
                            {
                              label: 'Type',
                              value: this.state.KYCCheckInformation?.response
                                ?.classification?.classificationDetails?.type
                            },
                            {
                              label: 'Version',
                              value: this.state.KYCCheckInformation?.response
                                ?.classification?.classificationDetails?.version
                            },
                            {
                              label: 'Issuer code',
                              value: this.state.KYCCheckInformation?.response
                                ?.classification?.classificationDetails
                                ?.issuerCode
                            },
                            {
                              label: 'Issuer name',
                              value: this.state.KYCCheckInformation?.response
                                ?.classification?.classificationDetails
                                ?.issuerName
                            },
                            {
                              label: 'Classification name',
                              value: this.state.KYCCheckInformation?.response
                                ?.classification?.classificationDetails
                                ?.classificationName
                            }
                          ]}
                        />
                        <KycCollapsibleItem
                          title="Meta data"
                          arrayOfItems={[
                            {
                              label: 'Name',
                              value: this.state.KYCCheckInformation?.response
                                ?.verification?.metaData?.name
                            },
                            {
                              label: 'Date of birth',
                              value: this.state.KYCCheckInformation?.response
                                ?.verification?.metaData?.dateOfBirth
                            },
                            {
                              label: 'Document number',
                              value: this.state.KYCCheckInformation?.response
                                ?.verification?.metaData?.documentNumber
                            },
                            {
                              label: 'Document expiry date',
                              value: this.state.KYCCheckInformation?.response
                                ?.verification?.metaData?.documentExpiryDate
                            }
                          ]}
                        />
                        <dl>
                          <div
                            style={{
                              cursor: 'pointer',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: 20
                            }}
                            onClick={() =>
                              this.toggleCollapse('kycTestsTableCollapsed')
                            }
                          >
                            <h4>Tests:</h4>
                            <i
                              className={`fa fa-chevron-${
                                this.state.kycTestsTableCollapsed
                                  ? 'up'
                                  : 'down'
                              }`}
                            />
                          </div>
                          <hr />
                          {this.state.kycTestsTableCollapsed && (
                            <table className="table">
                              <tbody>
                                <tr className="tr-dark-bg">
                                  <td>Test</td>
                                  <td>Description</td>
                                  <td>Result</td>
                                </tr>
                                {this.state.KYCCheckInformation?.response?.verification?.alerts.map(
                                  item => {
                                    return (
                                      <tr
                                        key={`${item.name + item.description}`}
                                      >
                                        <td>{item.name}</td>
                                        <td>{item.description}</td>
                                        <td>
                                          {this.renderResult(item.result)}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          )}
                        </dl>
                        <KycCollapsibleItem
                          title="Address Check"
                          repeatCheck={() => this.repeatCheck('DETAILS_CHECK')}
                          arrayOfItems={[
                            {
                              label: 'Message',
                              value: this.state.uboDetailsResponse?.response
                                ?.results?.w2DataEkycUk007BResult?.message
                            },
                            {
                              table: this.state.uboDetailsResponse?.response
                                ?.results?.w2DataEkycUk007BResult?.dataSources,
                              columns: [
                                'source',
                                'sourceName',
                                'numberOfMatches',
                                'matchingCriteria'
                              ]
                            },
                            {
                              table: this.state.uboDetailsResponse?.response
                                ?.results?.w2DataEkycUk005Result?.dataSources,
                              columns: [
                                'source',
                                'sourceName',
                                'numberOfMatches',
                                'matchingCriteria'
                              ]
                            }
                          ]}
                        />
                        <KycCollapsibleItem
                          title="PEP Result"
                          repeatCheck={() => this.repeatCheck('PEP')}
                          onActionClicked={profileId =>
                            this.repeatCheck('PEP_DETAILS', profileId, 'pep')
                          }
                          arrayOfItems={[
                            {
                              label: 'Service interpret result',
                              value: this.state.PEPCheckInformation?.response
                                ?.transaction?.serviceTransactions?.[0]
                                ?.serviceInterpretResult
                            },
                            {
                              table: this.state.PEPCheckInformation?.response
                                ?.results?.pepDeskCheckResult?.matchResults,
                              columns: [
                                'name',
                                'matchType',
                                'customDateOfBirth',
                                'nameMatchScore',
                                'action'
                              ]
                            }
                          ]}
                        />
                        <PepDetailsModal
                          isOpen={this.state.showPepDetailsModal}
                          onClose={() =>
                            this.setState({
                              showPepDetailsModal: false,
                              pepDetails: []
                            })
                          }
                          items={this.state.pepDetails}
                        />
                        <SisDetailsModal
                          isOpen={this.state.showSisDetailsModal}
                          onClose={() =>
                            this.setState({
                              showSisDetailsModal: false,
                              sisDetails: []
                            })
                          }
                          items={this.state.sisDetails}
                        />
                        <KycCollapsibleItem
                          title="SIS Result"
                          repeatCheck={() => this.repeatCheck('SIS')}
                          onActionClicked={profileId =>
                            this.repeatCheck('PEP_DETAILS', profileId, 'sis')
                          }
                          arrayOfItems={[
                            {
                              label: 'Service interpret result',
                              value: this.state.SISCheckInformation?.response
                                ?.transaction?.serviceTransactions?.[0]
                                ?.serviceInterpretResult
                            },
                            {
                              table: this.state.SISCheckInformation?.response
                                ?.results?.sisPlusCheckResult?.matchResults,
                              columns: [
                                'name',
                                'matchType',
                                'customDateOfBirth',
                                'nameMatchScore',
                                'action'
                              ]
                            }
                          ]}
                        />
                        <KycCollapsibleItem
                          repeatCheck={() => this.repeatCheck('COMPARE')}
                          title="Selfie Result"
                          arrayOfItems={[
                            {
                              label: 'Selfie match',
                              value:
                                this.state.compareResults?.response &&
                                'isMatch' in this.state.compareResults?.response
                                  ? this.state.compareResults?.response?.isMatch
                                    ? 'True'
                                    : 'False'
                                  : '-'
                            },
                            {
                              label: 'Selfie confidence',
                              value: this.state.compareResults?.response
                                ?.confidence
                                ? `${this.state.compareResults?.response?.confidence}%`
                                : '-',
                              isDate: false
                            }
                          ]}
                        />
                      </div>
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>KYC Documents Submission</h4>
                        <hr />
                        <div className="d-flex align-items-center justify-content-center flex-column">
                          {this.state.KYCDocumentsUrls.length ? (
                            this.state.KYCDocumentsUrls.map((item, index) => {
                              return (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                  }}
                                  key={item.publicUrl}
                                >
                                  <span>Document type: {item.type}</span>
                                  <img
                                    src={item.publicUrl}
                                    style={{
                                      width: '100%',
                                      height: 'auto',
                                      maxWidth: 300,
                                      marginTop: 10,
                                      marginBottom: 40,
                                      cursor: 'pointer'
                                    }}
                                    alt="document"
                                    onClick={() =>
                                      this.openModal(item.publicUrl)
                                    }
                                  />
                                  <ModalDialog
                                    show={this.state.isModalOpen}
                                    onClose={this.closeModal}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column'
                                      }}
                                    >
                                      <img
                                        src={this.state.previewImageUrl}
                                        alt="Preview"
                                        style={{ width: 770 }}
                                      />
                                      <button
                                        type="button"
                                        onClick={() => this.closeModal()}
                                        className="button-info"
                                        style={{ marginTop: 20 }}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </ModalDialog>
                                </div>
                              );
                            })
                          ) : (
                            <h4 className="m-3">No KYC documents</h4>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(KycInfo);
